export const importManifest = `
  mutation ImportManifest($request: PaternityManifest!) {
    importManifest(request: $request) {
      success
    }
  }
`;

export const getNominatorAndCheckerList = `
query getNominatorAndCheckerList {
  getNominatorAndCheckerList {
    checkerList
    nominatorList
  }
}
`

export const getCasesByStatusType = `
  mutation getCasesByStatusType($request: GetCasesByStatusTypeInput) {
    getCasesByStatusType(request: $request) {
      totalRecords
      totalPages
      cases {
        externalCaseId
        caseNumber
        externalProductCode
        personsInReport
        status
        reports {
            reportId
            reportType
            status
            checker
            nominator
            reportPersons {
              personId
              type
              order
          }
        }
        persons {
          personId
          name
          firstName
          lastName
          gender
          dateOfBirth
          samples{
            labNumber
            specimenNumber
            collectedDate
            receivedDate
            comments
            status
            isConditionValid
            isDocumentationValid
          }
          race
        }
      }  
    }
  }
`;

export const updateCasePersonsByReportId = `
  mutation updateCasePersonsByReportId($request: UpdateCasePersonsByReportIdInput!) {
    updateCasePersonsByReportId(request: $request) {
      success
      errorMessage
    }
  }
`;

export const upsertSpecimensForLims = `
  mutation upsertSpecimens($request: UpsertSpecimenRequest) {
    upsertSpecimens(request: $request) {
      isSuccess
    }
  }
`;

export const updateReportVerifiers = `
  mutation updateReportVerifiers($request: UpdateReportVerifiersInput) {
    updateReportVerifiers(request: $request) {
      success
      errorMessage
    }
  }
`;

export const updateCaseOrder = `
  mutation updateOrderForSpecimenReception($request: UpdateOrderInput) {
    updateOrderForSpecimenReception(request: $request) {
      success
      errorMessage
    }
  }
`;

export const updateOrderStatus = `
  mutation updateCaseStatusToResultAvailableToDeliver($request: UpdateCaseStatusUsingCaseNumberInput) {
    updateCaseStatusToResultAvailableToDeliver(request: $request) {
      success
      errorMessage
    }
  }
`;

export const updateSampleStatus = `
  mutation updateSampleStatusInput($request: UpdateSampleStatusInput) {
    updateSampleStatusInput(request: $request) {
      success
      errorMessage
    }
  }
`;

export const updateCaseStatusUsingCaseNumber = `
  mutation updateCaseStatusUsingCaseNumber($request: UpdateCaseStatusInput) {
    updateCaseStatusUsingCaseNumber(request: $request) {
      success
      errorMessage
    }
  }
`;

export const processKinshipAnalysis = `
  mutation processKinshipAnalysis($request: ProcessKinshipAnalysisInput) {
    processKinshipAnalysis(request: $request) {
      success
      errorMessage
    }
  }
`;
