import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";

interface ToastViewProps {
  messageType: string;
  message: string;
  showMessage: boolean | undefined;
  closeToast: Function;
}

const ToastView = (props: ToastViewProps) => {
  return (
    <ToastContainer className="p-3" position="top-end">
      <Toast show={props.showMessage} onClose={() => props.closeToast()}>
        <Toast.Header>
          <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
          <strong className="me-auto">{props.messageType}</strong>
        </Toast.Header>
        <Toast.Body>{props.message}</Toast.Body>
      </Toast>
    </ToastContainer>
  );
};

export default ToastView;
