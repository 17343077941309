import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import MainContainer from "../pages/MainContainer";
import { RootState } from "../store";

interface ProtectedRouteProps {
  element: JSX.Element;
}

function ProtectedRoute(props: ProtectedRouteProps) {
  const username = useSelector((state: RootState) => state.login.username);

  if (username === "") {
    // user is not authenticated
    return <Navigate to="/" />;
  }

  return <MainContainer>{props.element}</MainContainer>;
}

export default ProtectedRoute;
