import moment from "moment";
import { IPaternityManifest } from "../../models/PaternityManifest";
import { ProductCodes } from "../../constants/ProductCodes";
import { PersonCodes } from "../../constants/PersonCodes";

const parseFullName = require('parse-full-name');

export const getManifestImportRequest = (trackingNumber: string, jsonString: string): IPaternityManifest => {
  return {
    trackingNumber: trackingNumber,
    lines: JSON.parse(jsonString, function (key, value) {
        return key === "dateOfBirth"
          ? getDate(value)
          : value;
      }
    ),
  };
}

export const splitPersonNames = (manifest: IPaternityManifest) => {
  manifest.lines.forEach(line => {
    const name = parseFullName.parseFullName(line.name);

    line.firstname = name.first;
    line.lastname = name.last;
  });
}

export const getDate = (value: any) => {
  const date = moment(value, ["DD/MM/YYYY", "D/MM/YYYY", "D/M/YYYY"] , true);
  if (!date.isValid())
    throw new Error(`Invalid date (${value}) found!`);

  return date.format("YYYY-MM-DD");
}

/* 
AM - Wrote this function to manually set the case type
because we have plenty of logic in backend that relies on this.
Also, EasyDNA might start sending us this value as part of the Manifest.
It will be easier for us to remove the code from just frontend.
*/
export const setCaseTypes = (manifest: IPaternityManifest) => {
  manifest.lines.forEach(line => {
    line.caseType = getCaseType(line.testType);
  });
}

export const getCaseType = (testType: string) : string => {

  // Split the code on the + character
  var personCodes = testType.split('+');

  // Extract just the alphabetic characters from the string
  personCodes = personCodes.map(code => 
    code.toUpperCase()
    .replace(/[^A-Za-z]/g, '')
    // Remove the Legal product string ("LEG") to leave only characters representing people
    .replace(/LEG/g, ''));

  var isLegal = testType.startsWith(ProductCodes.LegalProduct);

  // Paternity code
  if (personCodes.includes(PersonCodes.AllegedFather) && personCodes.includes(PersonCodes.Child)) {

    if (personCodes.length === 2) return isLegal ? ProductCodes.LegalPaternityDuo : ProductCodes.PaternityDuo;
    if (personCodes.length === 3 && personCodes.includes(PersonCodes.Mother)) return isLegal ? ProductCodes.LegalPaternityTrio : ProductCodes.PaternityTrio;

  } 
  else if (
    personCodes.length === 2 && 
    personCodes.includes(PersonCodes.Mother) && 
    personCodes.includes(PersonCodes.Child)
  ) {
  	// Maternity product
    return isLegal ? ProductCodes.LegalMaternityDuo : ProductCodes.MaternityDuo;
  }

  throw new Error(`Invalid test type found: ${testType}`)
}
