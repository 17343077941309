import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface LoginState {
  username: string
}

const initialState: LoginState = {
  username: "",
}

export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<string>) => {
      state.username = action.payload
    },
    clearUser: (state) => {
      state.username = ""
    },
  },
})

// Action creators are generated for each case reducer function
export const { setUser, clearUser } = loginSlice.actions

export default loginSlice.reducer