interface CsvTableRowsProps {
  rows: any[];
}

function CsvTableRows(props: CsvTableRowsProps) {
  return (
    <tbody>
      {props.rows.map((value: any, index: number) => {
        return (
          <tr key={index}>
            {value.map((val: any, i: number) => {
              return <td key={i}>{val}</td>;
            })}
          </tr>
        );
      })}
    </tbody>
  );
}

export default CsvTableRows;
