import Button from "../../components/Button";
import Modal from 'react-bootstrap/Modal';
import { SpecimenInput } from '../../models/UpsertSpecimenRequest';
import { useState } from "react";
import LimsPersonView from '../LimsPersonView';
import "./style.css";

interface LimsProps {
  limsData: any;
  caseNumber: string;
  handleClose: Function;
  handleSendToLims: Function;
  isShow: boolean;
}

const LimsDataModal = (props: LimsProps) => {
  const {limsData, caseNumber, handleClose, isShow, handleSendToLims} = props;

  const [isProcessing, setIsProcessing] = useState(false);
  
  const renderPersons = () => {
    return limsData.specimens.map((l : SpecimenInput) => {
      return <div className="lims-entries" key={l.barcode}><LimsPersonView limsData={l} /></div>
    })
  }

  const sendToLims = () => {
    setIsProcessing(true)
    handleSendToLims()
  }

    return (
        <>
          <Modal show={isShow} onHide={() => handleClose()}>
            <Modal.Header closeButton>
              <Modal.Title>Summary For CaseNumber: {caseNumber}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{renderPersons()}</Modal.Body>
            <Modal.Footer>
              <Button title="Back to review" variant="secondary" handleClick={() => handleClose()} disabled={isProcessing} />
              <Button title="Send To Lims" variant="primary" handleClick={() => sendToLims()}  isLoading={isProcessing} />
            </Modal.Footer>
          </Modal>
        </>
      );
}

export default LimsDataModal