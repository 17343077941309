interface ContainerProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
}

function Container(props: ContainerProps) {
  return (
    <div className="container" style={props.style}>
      {props.children}
    </div>
  );
}

export default Container;
