
import { SpecimenInput } from '../../models/UpsertSpecimenRequest';
import { Row, Col } from "react-bootstrap";
import "./style.css";

interface LimsPerson {
  limsData: SpecimenInput;
}

const LimsPersonView = (props: LimsPerson) => {
  const { limsData } = props;

  return (
    <>
      <Row>
        <Col md={6} className="lims-label">First Name: <span className='lims-entry-value'>{limsData.firstName}</span></Col>
        <Col md={6} className="lims-label">Last Name: <span className='lims-entry-value'>{limsData.lastName}</span></Col>
        <Col md={6} className="lims-label">Date Of Birth: <span className='lims-entry-value'>{limsData.dateOfBirth.toString()}</span></Col>
        <Col md={6} className="lims-label">Specimen Id: <span className='lims-entry-value'>{limsData.specimenId}</span> </Col>
        <Col md={6} className="lims-label">Barcode: <span className='lims-entry-value'>{limsData.barcode}</span></Col>
        <Col md={6} className="lims-label">Comments: <span className='lims-entry-value'>{limsData.comment} </span></Col>
      </Row>
    </>
  );
}

export default LimsPersonView