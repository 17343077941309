import Field from "../../components/Field";

interface TrackingInfoProps {
  trackingNumber: string;
  setTrackingNumber: (value: string) => void;
}

function TrackingInfo(props: TrackingInfoProps) {
  return (
    <div className="tracking-field">
      <Field
        title="Tracking Number"
        value={props.trackingNumber}
        onChange={(e: any) => props.setTrackingNumber(e.target.value)}
        type="text"
      />
    </div>
  );
}

export default TrackingInfo;
