import Form from "react-bootstrap/Form";
import { ReactNode } from 'react';

interface FieldProps {
  title: string;
  value: string;
  placeholder?: string;
  onChange?: Function;
  onKeyDown?: Function;
  type?: string;
  fieldStyle?: React.CSSProperties;
  labelStyle?: React.CSSProperties;
  styles?:  React.CSSProperties;
  children?: ReactNode;
}

function Field(props: FieldProps) {
  return (
    <Form.Group className="mb-3" style={props.styles}>
      <Form.Label style={props.labelStyle} >{props.title}</Form.Label>
      <Form.Control
        type={props.type}
        placeholder={props.placeholder}
        value={props.value}
        onChange={(e) => props.onChange && props.onChange(e)}
        onKeyDown={(e) => props.onKeyDown && props.onKeyDown(e)}
        style={props.fieldStyle}
      />
      {props.children}
    </Form.Group>
  );
}

export default Field;
