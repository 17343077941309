import "./style.css";
import CsvTableHeader from "./CsvTableHeader";
import CsvTableRows from "./CsvTableRows";

interface CsvTableRendererProps {
  headers: any[];
  rows: any[];
}

function CsvTableRenderer(props: CsvTableRendererProps) {
  return (
    <table>
      <CsvTableHeader headers={props.headers} />
      <CsvTableRows rows={props.rows} />
    </table>
  );
}

export default CsvTableRenderer;
