interface CsvTableHeaderProps {
  headers: any[];
}

function CsvTableHeader(props: CsvTableHeaderProps) {
  return (
    <thead>
      <tr>
        {props.headers.map((columnName: any, index: number) => {
          return <th key={index}>{columnName}</th>;
        })}
      </tr>
    </thead>
  );
}

export default CsvTableHeader;
