import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import "./style.css";

interface CheckBoxInterface {
    text: string,
    isDisabled: boolean
    value: boolean
    onChange: Function
}

function Checkbox(props: CheckBoxInterface) {
    return (
        <> <InputGroup>
            <Form.Check aria-label="option 1" checked={props.value} onChange={(e) => props.onChange(e.currentTarget.checked)} disabled={props.isDisabled}/>
            <div className='label-text'>{props.text}</div>
        </InputGroup>
        </>
    );
}

export default Checkbox;