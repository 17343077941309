import React, { useState, KeyboardEvent, useRef, FormEvent, useEffect } from "react";
import { Accordion, Container, Pagination, Spinner } from "react-bootstrap";
import Field from "../../components/Field";
import Button from "../../components/Button";
import { ProcessKinshipAnalysis, UpdateCaseStatusToApproved,UpdateReportVerifiers,  GetCaseWithType, FetchDocumentUrl, UpdateCaseStatusUsingCaseNumber, GenerateReport, GetNominatorAndCheckerList } from "../../api";
import { Case, Report, ReportPerson, Person } from "../../models/GetCaseByCaseNumber";
import ToastView from "../../components/ToastView";
import { ReadFile, ExtractFilenameFromUrl } from './utils';
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { ProcessKinshipAnalysisInput } from "../../models/ProcessKinshipAnalysisInput";
import DropDownField from "../../components/DropDown";
import { saveAs } from "file-saver";
import ConfirmationModal from "../../components/ConfirmationModal";
import { ReportStatuses } from '../../constants/ReportStatuses';

const reportStatuses = {
    all: "all",
    created: "created",
    specimenReceived: "specimen received", 
    awaitingApproval: "awaiting approval", 
    resultAvailable: "result available to deliver", 
    complete: "complete", 
    specimenError: "specimen error"
};

export interface NominatorChecker {
    isModal: boolean,
    reportId: string
}

export interface ProcessingReport {
    isProcessingFile: boolean,
    reportId: string
}

const Reports = () => {
    const username = useSelector((state: RootState) => state.login.username);
    var AwaitingApproval = "Awaiting Approval";
    var ResultAvailableToDeliver = "Result Available to Deliver";
    var Complete = "Complete";
    var [caseNumber, setCaseNumber] = useState<string>("");
    var [activeAccordion, setActiveAccordion] = useState<string>("");
    var [isUpdatingStatus, setIsUpdatingStatus] = useState<boolean>(false);
    var [isMovingCaseToComplete, setIsMovingCaseToComplete] = useState<boolean>(false);
    var [toggleMoreInfo, setToggleMoreInfo] = useState<boolean>(false);
    var [statusType, setStatusType] = useState<string>("Select Case Status Type");
    var [totalRecords, setTotalRecords] = useState<number>(0);
    var [processingFile, setProcessingFile] = useState<ProcessingReport>({ isProcessingFile: false, reportId: "" });
    var [totalPages, setTotalPages] = useState<number>(0);
    var [activePage, setActivePage] = useState<number>(1);
    var [isLoading, setIsLoading] = useState<boolean>(false);
    var [isUpdatingVerifier, setIsUpdatingVerifier] = useState<boolean>(false);
    var [isLoadingCases, setIsLoadingCases] = useState<boolean>(false);
    var [caseData, setCaseData] = useState<Case[] | null>(null);
    var [nominatorsList, setNominatorList] = useState<string[]>(["Nominator"]);
    var [checkerList, setCheckerList] = useState<string[]>(["Checker"]);

    var [showNominatorCheckerModal, setShowNominatorCheckerModal] = useState<NominatorChecker>({ isModal: false, reportId: ""});
    var [uploadCaseId, setUploadCaseId] = useState<string | null>(null);
    var [uploadReportId, setUploadReportId] = useState<string | null>(null);

    var [toastMessage, setToastMessage] = useState<string>("");
    var [toastType, setToastType] = useState<string>("");
    var [showToast, setShowToast] = useState<boolean>(false);
    var uploadFileRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        GetNominatorAndCheckerList().then((data) => {
            setCheckerList(data.data.getNominatorAndCheckerList.checkerList)
            setNominatorList(data.data.getNominatorAndCheckerList.nominatorList)
        })
    },[])

    var onCaseNumberChange = (e: any) => {
        setCaseNumber(e.target.value);
    }

    var onCaseNumberKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Enter' && caseNumber.length >= 4) {
            onHandleSearchCase();
        }
    };

    var GetCaseWithTypeMethod = async (caseNumber : string, pageNumber : number, caseStatus : string) => {
        var request = {
            pageNumber: pageNumber,
            caseNumberOrExternalId:  caseNumber,
            statusType: caseStatus
        };
        return await GetCaseWithType(request);
    }

const generatePaginationItems = (totalPages : any, activePage : any, resolveActivePage : any) => {
    const items = [];
    for (let number = 1; number <= totalPages; number++) {
        items.push(
            <Pagination.Item key={number} active={number === activePage} onClick={() => resolveActivePage(number)}>
                {number}
            </Pagination.Item>
        );
    }
    return items;
};

    var resolveActivePage = async (pageNumber: number) => {
        setActivePage(pageNumber);
        await UpdateCaseWithParameters(caseNumber, pageNumber, statusType);
    }

    var resolveTypeChange = async (type: string) => {
        if (
            (
                type !== reportStatuses.all &&
                type !== reportStatuses.complete
                ) ||
            caseNumber.length >= 4
        ) {
            setStatusType(type)
            setActivePage(1)
            await UpdateCaseWithParameters(caseNumber, 1, type)
        }
    }

    var onClearCaseNumber = () => {
        setCaseNumber("");
        setCaseData(null);
        setTotalRecords(0);
        setTotalPages(0);
    }

    var onHandleSearchCase = () => {
        setIsLoadingCases(true)
        setIsLoading(true);
        GetCaseWithTypeMethod(caseNumber, 1, statusType)
        .then((caseData) => {
            setCaseData(caseData.data.getCasesByStatusType.cases);
            setTotalRecords(caseData.data.getCasesByStatusType.totalRecords);
            setTotalPages(caseData.data.getCasesByStatusType.totalPages);
            setActivePage(1)
        }).catch((e) => {
            setToastMessage("Unable to populate case information, please contact IT");
            setToastType("Error");
            setShowToast(true);
            console.error("Error when fetching case", e);
            setCaseData(null);
            setIsLoading(false);
        }).finally(() => {
            setIsLoading(false)
            setIsLoadingCases(false)
        })
    }

    var UpdateCaseAfterStatusUpdate = async () => {
        try {
            var caseData = await GetCaseWithTypeMethod(caseNumber, activePage, statusType);
            setCaseData(caseData.data.getCasesByStatusType.cases);
            setTotalRecords(caseData.data.getCasesByStatusType.totalRecords);
            setTotalPages(caseData.data.getCasesByStatusType.totalPages);
        }
        catch {
            setToastMessage("Unable to get updated case information, please contact IT");
            setToastType("Error");
            setShowToast(true);
        }
    }

    var UpdateCaseWithParameters = async (caseNumber: string, currentPage: number, caseStatusType: string) => {
        setIsLoadingCases(true);
        try {
            var caseData = await GetCaseWithTypeMethod(caseNumber, currentPage, caseStatusType);
            setCaseData(caseData.data.getCasesByStatusType.cases);
            setTotalRecords(caseData.data.getCasesByStatusType.totalRecords);
            setTotalPages(caseData.data.getCasesByStatusType.totalPages);
        }
        catch {
            setIsLoadingCases(false);
            setToastMessage("Unable to populate case information, please contact IT");
            setToastType("Error");
            setShowToast(true);
        }
        setIsLoadingCases(false);
    }

    var handleSubmitKinshipAnalysis = (event: FormEvent, caseId: string, reportId: string) => {
        setUploadCaseId(caseId);
        setUploadReportId(reportId);
        uploadFileRef!.current!.click();
    }

    const saveFile = async (url: string, name: string) => {
        let data = await fetch(url)
        const pdfBlob = await data.blob();
        const blob = new Blob([pdfBlob], { type: "application/pdf" });
        saveAs(blob, name);
    }

    const openFileInView = (url:string, name:string) => {
        saveAs(
          url,
          name
        );
      };

    var handleFileRetrieval = async (externalId: string, reportId: string, method: Function) => {
        setProcessingFile({ isProcessingFile: true, reportId: reportId });
        FetchDocumentUrl(reportId).then(async (response: any)=>{
          const docUrl = response.data.getReportDocumentUrlByReportId.documentUrl;
            if(docUrl === "no document found") {
                setToastMessage("No document found please contact IT");
                setToastType("Error");
                setShowToast(true);
            } else {
                // extract fileName from URL
                var fileName = ExtractFilenameFromUrl(docUrl);
                await method(docUrl, fileName)
          }
        }).catch(() => {
            setToastMessage("An error has occurred please contact IT");
            setToastType("Error");
            setShowToast(true);
        }).finally(()=>{
          setProcessingFile({ isProcessingFile: false, reportId: reportId });
        })
    }

    var handleRegenerateReport = async (reportId: string) => {
        setProcessingFile({ isProcessingFile: true, reportId: reportId });
        GenerateReport(reportId).then((response) => {
            const success = response.data.generateReport.success;

            if (!success) {
                const error = response.data.generateReport.errorMessage;
                console.error(error);
                setToastType("Error");
                setToastMessage(error ?? "An error occured while attempting to regenerate the report");
                setShowToast(true);
            } else {
                setToastType("Success");
                setToastMessage("Report is regenerating. Should be a few minutes until it's complete.");
                setShowToast(true);
            }
        }).catch((error) => {
            console.error(error);
            setToastType("Error");
            setToastMessage("An error occured while attempting to regenerate the report");
            setShowToast(true);
        }).finally(async () => {
            // The report regeneration will take some time, so put a short wait here to show feedback to user
            await new Promise(resolve => setTimeout(resolve, 30000));
            setShowToast(false);
            setProcessingFile({ isProcessingFile: false, reportId: reportId });
        })
    }

    var handleApproveReport = async (caseNumber: string) => {
        var request = { caseNumber: caseNumber}
        setIsUpdatingStatus(true);
        UpdateCaseStatusToApproved(request).then(async (res) => {
            if (res.data.updateCaseStatusToResultAvailableToDeliver.success) {
                await UpdateCaseAfterStatusUpdate();
            }
        }).finally(() => {
            setIsUpdatingStatus(false);
        })
    }

    var handleMoveCaseToComplete = async (caseNumber: string) => {
        var request = { caseId: caseNumber, status: Complete}
        setIsMovingCaseToComplete(true);
        UpdateCaseStatusUsingCaseNumber(request).then(async (res) => {
            if (res.data.updateCaseStatusUsingCaseNumber.success) {
                await UpdateCaseAfterStatusUpdate();
            }
        }).finally(() => {
            setIsMovingCaseToComplete(false);
        })
    }

    var updateNominator = async (caseNumber: string, reportId: string, nominator: string) => {
        const reportData = caseData?.find(c => c.caseNumber === caseNumber)?.reports.map((r) => {
            if (r.reportId === reportId) {
                r.nominator = nominator
            }
            return r;
        });
        var caseDataCopy = structuredClone(caseData);
        if (caseDataCopy != null) {
            var caseDataToUpdate = caseDataCopy.map((c: Case) => {
                if (c.caseNumber === caseNumber) {
                    if (reportData != null) {
                        c.reports = reportData
                    }
                }
                return c;
            })
            setCaseData(caseDataToUpdate);
        }

    }

    var updateChecker = async (caseNumber: string, reportId: string, checker: string) => {
        const reportData = caseData?.find(c => c.caseNumber === caseNumber)?.reports.map((r) => {
            if (r.reportId === reportId) {
                r.checker = checker
            }
            return r;
        });
        var caseDataCopy = structuredClone(caseData);
        if (caseDataCopy != null) {
            var caseDataToUpdate = caseDataCopy.map((c: Case) => {
                if (c.caseNumber === caseNumber) {
                    if (reportData != null) {
                        c.reports = reportData
                    }
                }
                return c;
            })
            setCaseData(caseDataToUpdate);
        }

    }

    var updateVerifiers = async (caseNumber: string, reportId: string) => {
        setIsUpdatingVerifier(true)
        var reportToUpdate = caseData?.find(c => c.caseNumber === caseNumber)?.reports.find(r => r.reportId === reportId);
        const NOMINATOR = "Nominator";
        const CHECKER = "Checker";
        var checkerRequest = {
            reportId: reportId,
            verifierType: CHECKER,
            verifierName: reportToUpdate?.checker ?? ""
        }
        var checkerResponse = await UpdateReportVerifiers(checkerRequest);
        if(!checkerResponse.data.updateReportVerifiers.success) {
            setToastMessage(`Unable to update checker for report ${reportId} case information, please contact IT`);
            setToastType("Error");
            setShowToast(true);
        }
        var nominatorRequest = {
            reportId: reportId,
            verifierType: NOMINATOR,
            verifierName: reportToUpdate?.nominator ?? ""
        }
        var nominatorResponse = await UpdateReportVerifiers(nominatorRequest);
        if(!nominatorResponse.data.updateReportVerifiers.success) {
            setToastMessage(`Unable to update nominator for report ${reportId} case information, please contact IT`);
            setToastType("Error");
            setShowToast(true);
        }
        setIsUpdatingVerifier(false)
        setShowNominatorCheckerModal({ isModal: false, reportId: ""})
    }

    var handleFileLoad = (event: FormEvent<HTMLInputElement>) => {
        var eventTarget = event.target as HTMLInputElement;
        var fileList = eventTarget.files;
        if (fileList !== null) {
            ReadFile(fileList[0])
            .then(fileContents => {

                var request: ProcessKinshipAnalysisInput = {
                    fileContents: fileContents as string,
                    reportId: uploadReportId!,
                    caseId: uploadCaseId!,
                    uploadUsername: username,
                    uploadTime: new Date()
                };

                ProcessKinshipAnalysis(request)
                .then(response => {
                    if (response.data.processKinshipAnalysis.success) {
                        setToastType("Success");
                        setShowToast(true);
                    } else {
                        setToastMessage("Paternity API failed to process the Kinship Analysis file. Please contact IT.");
                        setToastType("Error");
                        setShowToast(true);
                        console.error("Error response from Paternity API: " + response.data.processKinshipAnalysis.errorMessage);
                    }
                })
                .catch(error => {
                    setToastMessage("Failure in submitting Kinship Analysis file");
                    setToastType("Error");
                    setShowToast(true);
                    console.error("Error submitting kinship file: " + error);
                })
            })
            .catch(error => {
                setToastMessage("Unable to read kinship file");
                setToastType("Error");
                setShowToast(true);
                console.error("Error reading kinship file: " + error.Message);
            })

        }
    };

    const paginationItems = generatePaginationItems(totalPages, activePage, resolveActivePage);

    var generateCaseDisplay = (data: Case) => {
        return (
            // TODO Create a component for accordion and spinner
                <Accordion defaultActiveKey={activeAccordion} key={data.caseNumber}>
                    <Accordion.Item eventKey={data.caseNumber} onClick={() => activeAccordion === data.caseNumber ? setActiveAccordion("") : setActiveAccordion(data.caseNumber)}>
                        <Accordion.Header>CaseNumber: {data.caseNumber} <br></br>ExternalCaseId: {data.externalCaseId}</Accordion.Header>
                        <Accordion.Body>
                            <Container style={{ padding: "0.5rem 0" }}>
                                <Container style={{ padding: "0.5rem 0" }}>
                                    <h2>Case: {data.caseNumber}</h2>
                                    <h5>Status: {data.status}</h5>
                                    <p>External case id: {data.externalCaseId}</p>
                                    <p>External product code: {data.externalProductCode}</p>
                                    <Button isLoading={isUpdatingStatus} title="Approve Case Reports" disabled={data.status !== AwaitingApproval} handleClick={() => handleApproveReport(data.caseNumber)}></Button>
                                    <Button style={{ marginLeft: 10 }} isLoading={isMovingCaseToComplete} title="Move Case To Complete" disabled={data.status !== ResultAvailableToDeliver} handleClick={() => handleMoveCaseToComplete(data.caseNumber)}></Button>
                                    <Button style={{ marginLeft: 10 }} variant="secondary" title="?" handleClick={() => setToggleMoreInfo(!toggleMoreInfo)}></Button>
                                    {toggleMoreInfo && <div style={{ fontSize: 12 }}>Only Cases in "Awaiting Approval" status can be approved and only cases with status "Result Available to Deliver" can be moved to complete</div>}
                                </Container>
                                {data.reports.length > 0 && data.reports.map(report => generateReportDisplay(report, data))}
                            </Container>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
        );
    }
    
    var generateReportDisplay = (report: Report, data: Case) => {

        var reportPeopleIds = report.reportPersons.map(person => person.personId);

        var peopleDisplay = reportPeopleIds.map(id => {

            var reportPerson = report.reportPersons.find(person => person.personId === id);
            var casePerson = data.persons.find(person => person.personId === id);

            if (casePerson === undefined) throw new Error(`Person in report ${report.reportId} with ID ${id} can not be found on case`)

            return generatePersonDisplay(reportPerson!, casePerson);
        });

        return (
            <Container style={{padding: "0.5rem 0", marginBottom: "0.5rem"}} key={report.reportId}>
                <hr />
                <h3>Report {report.reportId}</h3>
                <h5>Type: {report.reportType}</h5>
                <h5>Status: {report.status}</h5>

                <input 
                    type="file" 
                    style={{display:"none"}} 
                    id="fileUpload" 
                    ref={uploadFileRef}
                    onChange={handleFileLoad}
                />
                <Button title="Upload Kinship Analysis" handleClick={(event: FormEvent) => handleSubmitKinshipAnalysis(event, data.caseNumber, report.reportId)} />
                <Button 
                    disabled={processingFile.isProcessingFile || report.status !== ReportStatuses.ReportGenerated} 
                    style={{marginLeft: 10}} 
                    title="Download Report" 
                    handleClick={() => handleFileRetrieval(data.externalCaseId, report.reportId, saveFile)} 
                />
                <Button 
                    disabled={processingFile.isProcessingFile || report.status !== ReportStatuses.ReportGenerated} 
                    variant="success" 
                    style={{marginLeft: 10}} 
                    title="View Report" 
                    handleClick={() => handleFileRetrieval(data.externalCaseId, report.reportId, openFileInView)} 
                />
                <Button 
                    disabled={
                        processingFile.isProcessingFile || 
                        (
                            report.status !== ReportStatuses.ReportGenerated &&
                            report.status !== ReportStatuses.AwaitingGeneration &&
                            report.status !== ReportStatuses.InErrorState
                        )
                    } 
                    variant="warning" 
                    style={{marginLeft: 10}} 
                    title="Regenerate Report" 
                    handleClick={() => handleRegenerateReport(report.reportId)} 
                />
                {processingFile.reportId === report.reportId && processingFile.isProcessingFile && <Spinner animation="border" role="status" size="sm" style={{marginLeft: 10}}></Spinner>}
                {report.status !== "Report Generated" && <div style={{fontSize: 12, marginTop: 10}}>Download report and view button are disabled as there is no report available</div>}
                <div>
                <div style={{float: "left", margin: 15}}>Nominator:</div>
                <DropDownField  
                        styles={{float: "left",marginTop: 10}} 
                        buttonTitle={report.nominator === "" ? "Select Reporter" : report.nominator} 
                        items= {nominatorsList} 
                        OnChange={(nominator : string) => updateNominator(data.caseNumber,report.reportId, nominator)} 
                        selectedValue={report.nominator}
                    />
                    <div style={{float: "left", margin: 15}}>Checker:</div>
                    <DropDownField  
                        styles={{float: "left", marginTop: 10}} 
                        buttonTitle={report.checker === "" ? "Select Checker" : report.checker} 
                        items={checkerList} 
                        OnChange={(checker : string) => updateChecker(data.caseNumber,report.reportId, checker)} 
                        selectedValue={report.checker} 
                    />
                    
                    <Button disabled={isUpdatingVerifier} variant="success" style={{marginLeft: 10, marginTop: 10}} title="Update Verifiers" handleClick={() => setShowNominatorCheckerModal({ isModal: true, reportId: report.reportId })} />
                    <ConfirmationModal message="Are you sure you want to update the checker and the nominator" heading="Checker and Nominator Confirmation Modal" handleClose={() => setShowNominatorCheckerModal({ isModal: false, reportId: "" })} handleConfirm={() => updateVerifiers(data.caseNumber,showNominatorCheckerModal.reportId)} isShow={showNominatorCheckerModal.isModal}/>
                    {isUpdatingVerifier && <Spinner animation="border" role="status" size="sm" style={{marginLeft: 10}}></Spinner>}

                </div>
                <div style={{clear: "both"}}></div>
                <Container style={{padding: "0.5rem"}}>
                    {peopleDisplay}
                </Container>
            </Container>
        );
    }

    var generatePersonDisplay = (reportPerson: ReportPerson, casePerson: Person) => {
        var sampleString = casePerson?.samples?.map(sample => sample.specimenNumber).join(", ") ?? null;

        return (
            <Container style={{padding: "0.5rem", border: "2px solid black", borderRadius: "30px", marginBottom: "0.5rem", backgroundColor: "#d3d3d3"}} key={reportPerson.personId}>
                <h5>{casePerson.name}</h5>
                <p>ID: {reportPerson.personId}</p>
                <p>Type: {reportPerson.type}</p>
                <p>DOB: {casePerson.dateOfBirth.toLocaleString()}</p>
                <p>Gender: {casePerson.gender}</p>
                {sampleString &&
                    <p>Samples: {sampleString}</p>
                }
            </Container>
        );
    }



    return (
        <Container style={{ marginTop: 50 }} fluid>
            <div className="case-search-header">Enter Case Number or External Case Number</div>
            <span id="getCaseInputContainer">
                <Field 
                    fieldStyle={{float: 'left', width: 200, marginRight: 20}} 
                    type="text" 
                    title="" 
                    value={caseNumber} 
                    onChange={onCaseNumberChange} 
                    onKeyDown={onCaseNumberKeyDown}
                    placeholder="Enter Case" 
                    labelStyle={{ fontSize: 16 }} >
                    <Button 
                        style={{float: 'left'}} 
                        title="Get Case" 
                        handleClick={onHandleSearchCase} 
                        disabled={caseNumber && caseNumber.length >= 4  ? false : true} 
                        isLoading={isLoading} 
                    />
                    <Button 
                        variant="danger" 
                        title="Clear" 
                        handleClick={onClearCaseNumber} 
                        disabled={caseNumber ? false : true} 
                        style={{ marginLeft: 20 }} 
                    />
                    <DropDownField  
                        styles={{float: "left", marginLeft: 20}} 
                        buttonTitle={statusType} 
                        items={[
                            reportStatuses.all,
                            reportStatuses.created,
                            reportStatuses.specimenReceived,
                            reportStatuses.awaitingApproval,
                            reportStatuses.resultAvailable,
                            reportStatuses.complete,
                            reportStatuses.specimenError
                        ]} 
                        OnChange={(type : string) => resolveTypeChange(type)} 
                        selectedValue={statusType} 
                    />
                </Field>
                <div style={{fontSize: 12}}>Please enter atleast 4 characters to search for a case. Wildcard search is only supported where a case type is selected. Exact case number is required if all is selected from case type</div>
                {caseData != null && <div>Total Records: {totalRecords}</div>}
            </span>
            <hr />
            {!isLoadingCases && caseData != null && caseData.length > 0 && caseData.map( sCase => {
                return generateCaseDisplay(sCase);
            })}
            {isLoadingCases && <div style={{textAlign: "center", marginTop: 100}}><Spinner animation="border" role="status">
                <span className="visually-hidden"></span>
            </Spinner></div>}
            <div style={{display: "flex", justifyContent: "center", marginTop: 20}}>
                {!isLoadingCases && <Pagination size="sm" >
                {paginationItems}
                </Pagination>}
            </div>
            <ToastView
                showMessage={showToast}
                messageType={toastType}
                message={toastMessage}
                closeToast={() => setShowToast(false)}
            />
        </Container>
    );
}

export default Reports;