import { IUpdateCasePersonsByReportIdInput } from "../../models/UpdateCasePersonsByReportIdInput"
import { IUpsertSpecimenRequest } from "../../models/UpsertSpecimenRequest";
import { Received } from "../../utils/constants";
import { getInitialsFromString } from "../../utils/helpers";


export const getUpdateCaseReportRequest = (reportIds: string[], caseData: any, isForLegalReport: boolean): IUpdateCasePersonsByReportIdInput => {
  var caseSpecimenReceptionData = caseData.persons.map((r: any) => {
    return {
      personId: r.personId,
      type: getType(caseData, r.personId),
      comment: r.samples?.comments,
      receivedDate: r.samples.receivedDate,
      collectedDate: r.samples.collectedDate,
      labNumber: `${r.samples.specimenNumber.toUpperCase()}-${getInitialsFromString(getType(caseData, r.personId))}`,
      specimenNumber: `${r.samples.specimenNumber.toUpperCase()}`,
      dateOfBirth: r.dateOfBirth,
      isConditionValid: r.samples.isConditionValid,
      isDocumentationValid: r.samples.isDocumentationValid,
      isLegalReportSample: isForLegalReport,
      completedAt: isForLegalReport ? r.samples.completedAt : "", 
      completedBy:isForLegalReport ? r.samples.completedBy : "",
      isChainOfCustodyCompleted: isForLegalReport ? r.samples.isChainOfCustodyCompleted : false,
      race: r.race
    }
  })
  return {
    reportIds: reportIds,
    specimenReceptionData: caseSpecimenReceptionData
  };
}

export const checkIfLegalReportDataIsValid = (caseData: any) => {
  var responses = caseData.map((r: any) => {
    if (r.isLegalReportSample && (r.completedAt === "" || r.completedBy === "" || !r.isChainOfCustodyCompleted)) {
      return false;
    }
    return true;
  })
  return responses.filter((re : any) => re === false).length > 0 ? false : true;
}

export const getLimsRequest = (persons: any, caseNumber: string, caseData: any): IUpsertSpecimenRequest => {
  var specimens = persons.filter((p: any) => p.samples.isConditionValid && p.samples.status !== Received).map((r: any) => {
    return {
      caseId: caseNumber,
      specimenId: r.samples.specimenNumber,
      dateReceived: ToIsoString(r.samples.receivedDate),
      lastName: r.lastName,
      firstName: r.firstName,
      dateOfBirth: r.dateOfBirth,
      barcode: `${r.samples.specimenNumber.toUpperCase()}-${getInitialsFromString(getType(caseData, r.personId))}`,
      comment: r.samples?.comments ?? ""
    }
  })
  return {
    specimens: specimens
  };
}

export const validateUserInput = (userInput: any) => {
  if(userInput.persons.filter((p: any) => !p.samples.isConditionValid && !p.samples.comments).length > 0)
  {throw new Error('Validation Error'); } 
}


const getType = (caseData: any, personId: any): string => {
  let all = caseData.reports.reduce((prev: any, next: any) => prev.concat(next.reportPersons), []);
  let result = all.find((obj: any) => obj.personId === personId)?.type;
  return result
}

export const getCaseDataWithOnlyActiveSample = (caseData: any) => {
  caseData.persons.filter((p: any) => p.samples = getActiveSample(p.samples, p.personId, caseData));
  return caseData;
}

const getActiveSample = (samples: any[], personId: any, caseData: any) => {
  if (samples.length > 0) {
    var numberOfSamples = samples.length;
    var sample = typeof samples.find((s: any) => s.status === "Active" || s.status === "Rejected" || s.status === Received) !== "undefined" ? samples.find((s: any) => s.status === "Active" || s.status === "Rejected" || s.status === Received) :
      {
        labNumber: `${personId}-${numberOfSamples + 1}`,
        specimenNumber: `${personId}-${numberOfSamples + 1}`,
        receivedDate: todaysDate(),
        collectedDate: todaysDate(),
        isConditionValid: false,
        isDocumentationValid: false,
        comments: ""
      }
    if (sample?.receivedDate && sample?.receivedDate.indexOf('T') > -1) { sample.receivedDate = sample.receivedDate.split('T')[0] }
    if (sample?.receivedDate && sample?.collectedDate?.indexOf('T') > -1) { sample.collectedDate = sample.collectedDate.split('T')[0] }
    return sample;
  }
  else {
    return {
      labNumber: `${personId}-${1}`,
      specimenNumber: `${personId}-${1}`,
      receivedDate: todaysDate(),
      collectedDate: todaysDate(),
      isConditionValid: false,
      isDocumentationValid: false,
      comments: ""
    }
  }
}

export const getDateOnly = (date: string) => {
  if (date && date.indexOf('T') > -1) {
    return date.split('T')[0]
  }
  return date
}

export const todaysDate = () => {
  var todaysDate = new Date().toLocaleDateString("fr-CA", { year: "numeric", month: "2-digit", day: "2-digit" })
  return todaysDate
};

export const ToIsoString = (date: any) => {
  return new Date(date).toISOString();
}