import Button from "../../components/Button";

interface ImportButtonProps {
  isImporting: boolean;
  handleImport: () => void;
}

function ImportButton(props: ImportButtonProps) {
  return (
    <div className="import-btn">
      <Button
        title="Import"
        isLoading={props.isImporting}
        handleClick={props.handleImport}
        style={{ width: "100%" }}
      />
    </div>
  );
}

export default ImportButton;
