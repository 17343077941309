import Button from "../../components/Button";
import Modal from 'react-bootstrap/Modal';
import "./style.css";

interface Props {
  handleClose: Function;
  handleConfirm: Function;
  message: String;
  heading: String;
  isShow: boolean;
}

const ConfirmationModal = (props: Props) => {
  const {handleClose, isShow, handleConfirm, message, heading} = props;



    return (
        <>
          <Modal show={isShow} onHide={() => handleClose()}>
            <Modal.Header closeButton>
              <Modal.Title>{heading}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{message}</Modal.Body>
            <Modal.Footer>
              <Button title="Cancel" variant="secondary" handleClick={() => handleClose()} />
              <Button title="Confirm" variant="primary" handleClick={() => handleConfirm()}  />
            </Modal.Footer>
          </Modal>
        </>
      );
}

export default ConfirmationModal