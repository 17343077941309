import { useState } from "react";
import CSVReader from "react-csv-reader";
import { ImportManifest } from "../../api";
import CsvTableRenderer from "../../components/CsvRenderer";
import ToastView from "../../components/ToastView";
import {
  getJsonString,
  validateManifest,
  trimCsvRows,
  extractMandatoryFields,
} from "../../utils/csvImportUtils";
import ImportButton from "./ImportButton";
import {
  getManifestImportRequest,
  setCaseTypes,
  splitPersonNames,
} from "./logic";
import "./style.css";
import TrackingInfo from "./TrackingInfo";

function CsvImport() {
  const [isImporting, setIsImporting] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("Error");

  const [trackingNumber, setTrackingNumber] = useState("");
  const [csvHeaders, setCsvHeaders] = useState<string[]>([]);
  const [csvRows, setCsvRows] = useState<any[]>([]);

  const handleCsvSelection = (data: any[]) => {
    // we are ignoring relationship field
    var indexOfRelationship = data[0].indexOf("Relationship")
    data[0].splice(indexOfRelationship, 1);
    setCsvHeaders(data[0]);
    var trimmedRows = trimCsvRows(data.slice(1));
    trimmedRows.forEach((row: any[]) => {
      row.splice(5, 1);
    })
    setCsvRows(csvRows);
    setCsvRows(trimmedRows);
  };

  const importManifestAsync = async () => {
    validateManifest(csvHeaders, csvRows);

    var payloadFields = extractMandatoryFields(csvHeaders, csvRows);

    const paternityManifest = getManifestImportRequest(
      trackingNumber.trim(),
      getJsonString(csvHeaders, payloadFields)
    );

    // Populate additional fields before send the manifest to backend
    splitPersonNames(paternityManifest);
    setCaseTypes(paternityManifest);

    await ImportManifest(paternityManifest); // call GraphQL mutation
  };

  const handleCsvImport = async () => {
    if (trackingNumber.trim().length === 0) {
      setMessage("Tracking number cannot be empty");
      setMessageType("Error");
      setShowMessage(true);
      return;
    }
    try {
      setIsImporting(true);

      await importManifestAsync();
      setMessage(
        `Manifest successfully imported with tracking number: ${trackingNumber}`
      );
      setMessageType("Success");
    } catch (error: any) {
      setMessage(error.message ?? JSON.stringify(error));
      setMessageType("Manifest Import Error");
    }

    setShowMessage(true);
    setIsImporting(false);
  };

  return (
    <div className="csv-import-container">
      <ToastView
        showMessage={showMessage}
        messageType={messageType}
        message={message}
        closeToast={() => setShowMessage(false)}
      />

      <CSVReader onFileLoaded={handleCsvSelection} />

      <div className="tracking-and-import">
        <TrackingInfo
          trackingNumber={trackingNumber}
          setTrackingNumber={setTrackingNumber}
        />
        <ImportButton
          isImporting={isImporting}
          handleImport={handleCsvImport}
        />
      </div>

      <CsvTableRenderer headers={csvHeaders} rows={csvRows} />
      <br />
    </div>
  );
}

export default CsvImport;
