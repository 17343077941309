import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

interface FieldProps {
  buttonTitle: string;
  selectedValue: string;
  items: string[];
  OnChange: Function;
  styles?:  React.CSSProperties;
}

const renderDropDown = (props: FieldProps) => {
  return props.items.map(item => {
    return (
      <Dropdown.Item style={{textTransform: 'capitalize'}} key={item} onClick={() => props.OnChange(item)}>{item}</Dropdown.Item>
    )
  })
}

function DropDownField(props: FieldProps) {
  return (
    <DropdownButton style={props.styles} id="dropdown-basic-button" title={props.buttonTitle.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}>
      {renderDropDown(props)}
  </DropdownButton>
  );
}

export default DropDownField;