export const ReadFile = async (file: File) => {

    let fileContents = await new Promise((resolve) => {
        var reader = new FileReader();
        reader.onload = (e) => resolve(reader.result);
        reader.readAsText(file, 'utf-8');
    });

    return fileContents;
}

export const ExtractFilenameFromUrl = (url: string) => {
    // strip out parameters
    var result = url.slice(0, url.indexOf('?'));
    // the result is a URL containing the path to the file
    // the file name is at the end, inside the directory tree
    return result.split('/').pop();
}