import { ManifestHeaders } from "../constants/ManifestHeaders";

export const ValidGenders = [ "M", "F" ];

export const ValidSample = [ "Buccal Swab", "Swab", "Blood", "FTA Card" ];

export const validateManifest = (csvHeaders: string[], csvRows: string[][]) => {

  var mandatoryFields = extractMandatoryFields(csvHeaders, csvRows)

  mandatoryFields.forEach((row, rowIndex) => {
    for (var columnIndex = 0; columnIndex < row.length; columnIndex++) {
      // 6 is the index of notes which can be empty
      if (((row[columnIndex] as string).trim().length === 0) && columnIndex !== 6) {
        throw new Error(
          `Manifest contains empty mandatory fields(s) | Row:${rowIndex + 1} Column:${columnIndex + 1}`
        );
      }
    }
  });

  validateGender(csvHeaders, csvRows);
  validateSampleType(csvHeaders, csvRows);
}

export const extractMandatoryFields = (csvHeaders: string[], csvRows: string[][]) => {

  var mandatoryHeaders = Object.values(ManifestHeaders);
  var mandatoryHeadersNotPresent = mandatoryHeaders.filter(header => !csvHeaders.includes(header));

  if(mandatoryHeadersNotPresent.length > 0)
    throw new Error(`Manifest is missing the following required columns: ${mandatoryHeadersNotPresent.join(', ')}`);

  var mandatoryColumnIndexes = csvHeaders.map((value, index) => {
    if (mandatoryHeaders.includes(value)) return index;
    return null;
  });

  var mandatoryFields = csvRows.map(row => row.filter((columnValue, index) => mandatoryColumnIndexes.includes(index)));

  return mandatoryFields;
};

export const validateGender = (csvHeaders: string[], csvRows: any[]) => {
  const genderColumnIndex = csvHeaders.findIndex(x => x === ManifestHeaders.Gender);

  csvRows.forEach((row: any[]) => {
    const genderValue = (row[genderColumnIndex] as string).trim().toUpperCase();
    if (!ValidGenders.find(x => x === genderValue)) {
      throw new Error("Please provide a valid gender (i.e., M or F)");
    }
  });
};

export const validateSampleType = (csvHeaders: string[], csvRows: any[]) => {
  const sampleColumnIndex = csvHeaders.findIndex(x => x === ManifestHeaders.Sample);

  csvRows.forEach((row: any[]) => {
    var sampleValue = (row[sampleColumnIndex] as string) ?? "";
    sampleValue = sampleValue.trim();
    if (!ValidSample.find(x => x === sampleValue)) {
      throw new Error("Please provide a valid sample Value (i.e., Buccal Swab, Swab, Blood, FTA Card)");
    }
  });
};

export const getJsonString = (csvHeaders: string[], csvRows: any[]) => {''
  let payload: { [key: string]: any }[] = [];
  csvRows.forEach((row: any[]) => {
    var obj: { [key: string]: any } = {};
    for (var i = 0; i < row.length; i++) {
      obj[mapKey(csvHeaders[i])] = row[i];
    }

    payload.push(obj);
  });

  return JSON.stringify(payload);
}

/* 
This function is responsible for mapping header from Manifest
CSV from EasyDNA to something that is meaningful to myDNA
NOTE: Make sure the mapped keys match ILine interface 
*/
export const mapKey = (csvHeader: string): string => {
  switch(csvHeader) {
    case ManifestHeaders.CaseReference: return "caseReference";
    case ManifestHeaders.TestRequired: return "testType";
    case ManifestHeaders.Name: return "name";
    case ManifestHeaders.Dob: return "dateOfBirth";
    case ManifestHeaders.Gender: return "gender";
    case ManifestHeaders.Sample: return "sampleType";
    case ManifestHeaders.Notes: return "comments";
    default: return "";
  }
}

export const trimCsvRows = (csvRows: string[][]) => {

  // Filter out any entries that only contain blank/empty/whitespace string arrays
  var result = csvRows.filter(rowArray => 
    !rowArray.every(rowArrayValue => !rowArrayValue)
    );

  return result
}