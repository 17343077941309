import { Routes, Route } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import SignIn from "../pages/SignIn";
import CsvImport from "../pages/CsvImport";
import SpecimenReception from "../pages/SpecimenReception";
import Reports from "../pages/Reports";

function Navigation() {
  return (
    <Routes>
      <Route path="/" element={<SignIn />} />
      <Route
        path="/csv-import/"
        element={<ProtectedRoute element={<CsvImport />} />}
      />
      <Route
        path="/specimen-reception/"
        element={<ProtectedRoute element={<SpecimenReception />} />}
      />
      <Route
        path="/reports/"
        element={<ProtectedRoute element={<Reports />} />}
      />
    </Routes>
  );
}

export default Navigation;
