import Nav from 'react-bootstrap/Nav';
import { Link as Navigation } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';

interface Props {
    handleLogout: () => void
}

const NavBar = (props: Props) => {
    const linkStyle = {
        margin: "1rem",
        textDecoration: "none",
        color: 'black',
        fontSize: '20px'
    };

    const logout = {
        margin: "1rem",
        textDecoration: "none",
        color: '#d70b77',
        fontWeight: 'bold',
        fontSize: '20px',
        cursor: 'pointer'
    };


    const title = {
        margin: "1rem",
        textDecoration: "none",
        color: 'black',
        fontWeight: 'bold',
        fontSize: '30px'
    };

    return (
        <Navbar bg="light" expand="lg">
            <div style={title}>Paternity Portal</div>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">
                    <Navigation to="/csv-import/" style={linkStyle}>Csv-Import</Navigation>
                    <Navigation to="/specimen-reception/" style={linkStyle}>Specimen-Reception</Navigation>
                    <Navigation to="/reports/" style={linkStyle}>Reports</Navigation>
                </Nav>
                <Nav>
                    <div onClick={() => props.handleLogout()} style={logout}>Logout</div>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
}


export default NavBar