import { default as ButtonController } from "react-bootstrap/Button";
import { ButtonVariant } from "react-bootstrap/esm/types";
import Spinner from "react-bootstrap/Spinner";

interface Props {
  title: string;
  handleClick: Function;
  variant?: ButtonVariant;
  style?: React.CSSProperties;
  isLoading?: boolean;
  disabled?: boolean;
}

function Button(props: Props) {
  return (
    <ButtonController
      style={props.style}
      variant={props.variant ?? "primary"}
      type="submit"
      disabled={props.disabled}
      onClick={() => props.handleClick()}
    >
      {props.isLoading && (
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />
      )}
      {!props.isLoading && props.title}
    </ButtonController>
  );
}

export default Button;
