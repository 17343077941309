import { API, Auth } from "aws-amplify";
import { 
  importManifest,
  getNominatorAndCheckerList, 
  updateReportVerifiers,
  updateCasePersonsByReportId, 
  getCasesByStatusType, 
  upsertSpecimensForLims, 
  updateCaseOrder, 
  updateSampleStatus, 
  processKinshipAnalysis, 
  updateOrderStatus, 
  updateCaseStatusUsingCaseNumber } from "../graphql/mutations";
import { getCase, getReportDocumentUrlByReportId, generateReport } from "../graphql/query";
import { IManifestImportResponse } from "../models/ManifestImportResponse";
import { GetCaseByCaseNumber } from "../models/GetCaseByCaseNumber";
import { IPaternityManifest } from "../models/PaternityManifest";
import { IUpdateCasePersonsByReportIdResponse,  IUpdateOrderInputResponse, IUpdateSampleInputResponse, ProcessKinshipAnalysisResponse, IUpdateCaseStatusUsingCaseNumberResponse, IUpdateReportVerifiersResponse, IUpdateCaseStatusInputResponse, GenerateReportResponse } from "../models/ResponseData";
import { IUpdateCasePersonsByReportIdInput} from "../models/UpdateCasePersonsByReportIdInput"
import { IGetReportDocumentUrlByReportIdResponse} from "../models/IGetReportDocumentUrlByReportIdResponse"
import { IUpsertSpecimenRequest, UpsertSpecimensResponse} from "../models/UpsertSpecimenRequest"
import { IUpdateOrderInput, IUpdateCaseStatusInput } from "../models/UpdateOrderInput"
import { IUpdateSampleInput } from "../models/IUpdateSampleInput";
import { IUpdateCaseStatusUsingCaseNumberInput} from "../models/UpdateCaseStatusUsingCaseNumberInput"
import { ProcessKinshipAnalysisInput } from '../models/ProcessKinshipAnalysisInput';
import { IGetCasesByStatusTypeInput, IGetCasesByStatusTypeResponse } from "../models/IGetCasesByStatusTypeInput";
import {IGetNominatedAncCheckerListResponse} from "../models/IGetNominatedAncCheckerListResponse"
import { IUpdateReportVerifiersRequest } from "../models/IUpdateReportVerifiersRequest";

export const ImportManifest = async (manifest: IPaternityManifest) => {
  const idToken = await getIdToken();
  return (await API.graphql({
    query: importManifest,
    variables: { request: manifest },
    authMode: "AMAZON_COGNITO_USER_POOLS",
    authToken: idToken,
  })) as {
    data: IManifestImportResponse;
  };
};

export const GetCase = async (caseNumber: String) => {
  const idToken = await getIdToken();
  return (await API.graphql({
    query: getCase,
    variables: { caseNumber: caseNumber },
    authMode: "AMAZON_COGNITO_USER_POOLS",
    authToken: idToken,
  })) as {
    data: GetCaseByCaseNumber;
  };
};

export const FetchDocumentUrl = async (reportId: String) => {
  const idToken = await getIdToken();
  return (await API.graphql({
    query: getReportDocumentUrlByReportId,
    variables: { reportId: reportId },
    authMode: "AMAZON_COGNITO_USER_POOLS",
    authToken: idToken,
  })) as {
    data: IGetReportDocumentUrlByReportIdResponse;
  };
};

export const UpdateCaseReports = async (request: IUpdateCasePersonsByReportIdInput ) => {
  const idToken = await getIdToken();
  return (await API.graphql({
    query: updateCasePersonsByReportId,
    variables: { request: request },
    authMode: "AMAZON_COGNITO_USER_POOLS",
    authToken: idToken,
  })) as {
    data: IUpdateCasePersonsByReportIdResponse;
  };
};

export const GetCaseWithType = async (request: IGetCasesByStatusTypeInput ) => {
  const idToken = await getIdToken();
  return (await API.graphql({
    query: getCasesByStatusType,
    variables: { request: request },
    authMode: "AMAZON_COGNITO_USER_POOLS",
    authToken: idToken,
  })) as {
    data: IGetCasesByStatusTypeResponse;
  };
};

export const UpdateSpecimenToLims = async (request: IUpsertSpecimenRequest ) => {
  const idToken = await getIdToken();
  return (await API.graphql({
    query: upsertSpecimensForLims,
    variables: { request: request },
    authMode: "AMAZON_COGNITO_USER_POOLS",
    authToken: idToken,
  })) as {
    data: UpsertSpecimensResponse;
  };
};

export const UpdateReportVerifiers = async (request: IUpdateReportVerifiersRequest ) => {
  const idToken = await getIdToken();
  return (await API.graphql({
    query: updateReportVerifiers,
    variables: { request: request },
    authMode: "AMAZON_COGNITO_USER_POOLS",
    authToken: idToken,
  })) as {
    data: IUpdateReportVerifiersResponse;
  };
};

export const UpdateOrder = async (request: IUpdateOrderInput ) => {
  const idToken = await getIdToken();
  return (await API.graphql({
    query: updateCaseOrder,
    variables: { request: request },
    authMode: "AMAZON_COGNITO_USER_POOLS",
    authToken: idToken,
  })) as {
    data: IUpdateOrderInputResponse;
  };
};

export const UpdateCaseStatusUsingCaseNumber = async (request: IUpdateCaseStatusInput ) => {
  const idToken = await getIdToken();
  return (await API.graphql({
    query: updateCaseStatusUsingCaseNumber,
    variables: { request: request },
    authMode: "AMAZON_COGNITO_USER_POOLS",
    authToken: idToken,
  })) as {
    data: IUpdateCaseStatusInputResponse;
  };
};

export const GetNominatorAndCheckerList = async () => {
  const idToken = await getIdToken();
  return (await API.graphql({
    query: getNominatorAndCheckerList,
    authMode: "AMAZON_COGNITO_USER_POOLS",
    authToken: idToken,
  })) as {
    data: IGetNominatedAncCheckerListResponse;
  };
};

export const UpdateCaseStatusToApproved = async (request: IUpdateCaseStatusUsingCaseNumberInput ) => {
  const idToken = await getIdToken();
  return (await API.graphql({
    query: updateOrderStatus,
    variables: { request: request },
    authMode: "AMAZON_COGNITO_USER_POOLS",
    authToken: idToken,
  })) as {
    data: IUpdateCaseStatusUsingCaseNumberResponse;
  };
};

export const UpdateSampleStatus = async (request: IUpdateSampleInput ) => {
  const idToken = await getIdToken();
  return (await API.graphql({
    query: updateSampleStatus,
    variables: { request: request },
    authMode: "AMAZON_COGNITO_USER_POOLS",
    authToken: idToken,
  })) as {
    data: IUpdateSampleInputResponse;
  };
};

export const ProcessKinshipAnalysis = async (request: ProcessKinshipAnalysisInput ) => {
  const idToken = await getIdToken();
  return (await API.graphql({
    query: processKinshipAnalysis,
    variables: { request: request },
    authMode: "AMAZON_COGNITO_USER_POOLS",
    authToken: idToken,
  })) as {
    data: ProcessKinshipAnalysisResponse;
  };
};

export const GenerateReport = async (reportId: String) => {
  const idToken = await getIdToken();
  return (await API.graphql({
    query: generateReport,
    variables: { reportId: reportId },
    authMode: "AMAZON_COGNITO_USER_POOLS",
    authToken: idToken,
  })) as {
    data: GenerateReportResponse;
  };
};

export const getIdToken = async () => {
  const currentSession = await Auth.currentSession();
  const token = currentSession.getIdToken();
  return token.getJwtToken();
};
