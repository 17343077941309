import { Auth } from "aws-amplify";
import { useDispatch } from "react-redux";
import NavBar from "../../components/NavBar";
import { clearUser } from "../../reduxSlices/loginSlice";

interface MainContainerProps {
  children: React.ReactNode;
}

function MainContainer(props: MainContainerProps) {
  const dispatch = useDispatch();

  const handleLogout = () => {
    Auth.signOut().then(() => {
      dispatch(clearUser());
    });
  };

  return (
      <div>
      <NavBar handleLogout={handleLogout}/>
      {props.children}
    </div>
  );
}

export default MainContainer;
