interface BoxProps {
  children: React.ReactNode;
}

function Box(props: BoxProps) {
  return (
    <div
      className="box"
      style={{
        boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
        borderRadius: 8,
        marginTop: 40,
        minWidth: 500,
        padding: 25,
      }}
    >
      {props.children}
    </div>
  );
}

export default Box;
