export const getCase = /* GraphQL */ `
query getCaseByCaseNumber($caseNumber: String!){
    getCaseByCaseNumber(caseNumber: $caseNumber){
        externalCaseId
        caseNumber
        externalProductCode
        personsInReport
        status
        persons
            {
                personId
                name
                firstName
                lastName
                gender
                dateOfBirth
                samples{
                    labNumber
                    specimenNumber
                    collectedDate
                    receivedDate
                    comments
                    status
                    isConditionValid
                    isDocumentationValid
                    isChainOfCustodyCompleted
                    isLegalReportSample
                    completedBy
                    completedAt
                }
                race
            }
        reports {
            reportId
            reportType
            status
            reportPersons {
                personId
                type
                order
            }
        }
    }
}`

export const getReportDocumentUrlByReportId = /* GraphQL */ `
query getReportDocumentUrlByReportId($reportId: String!){
    getReportDocumentUrlByReportId(reportId: $reportId){
        documentUrl 
    }
}`


export const generateReport = `
  query generateReport($reportId: String!) {
    generateReport(reportId: $reportId) {
      success
      errorMessage
    }
  }
`;