import { useState, useEffect } from "react";
import Container from "../../components/Container";
import Box from "../../components/Box";
import ImageSrc from "../../components/ImageSrc";
import myDnaLogo from "../../assets/logos/myDNA-white-bg-logo.png";
import Field from "../../components/Field";
import Button from "../../components/Button";
import ToastView from "../../components/ToastView";
import { Auth } from "aws-amplify";
import { setUser } from "../../reduxSlices/loginSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";

function SignIn() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const username = useSelector((state: RootState) => state.login.username);
  const dispatch = useDispatch();

  useEffect(() => {
    if (username !== "") {
      navigate("/csv-import/");
    }
  }, [navigate, username]);

  const handleLogin = async () => {
    setIsLoading(true);

    Auth.signIn(email, password)
      .then((user: any) => {
        const username: string =
          user.signInUserSession.accessToken.payload.username;

        dispatch(setUser(username));
        setIsLoading(false);
      })
      .catch((error) => {
        setShowMessage(true);
        setMessage(String(error));
        setIsLoading(false);
      });
  };

  return (
    <Container
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <ToastView
        showMessage={showMessage}
        messageType="Error"
        message={message}
        closeToast={() => setShowMessage(false)}
      />
      <Box>
        <ImageSrc
          imageSrc={myDnaLogo}
          imageAlt="myDnaLogo"
          styles={{ height: 50, margin: "20px 0 20px 0px" }}
        />
        <Field
          title="Email"
          value={email}
          onChange={(e: any) => setEmail(e.target.value)}
          type="text"
        />
        <Field
          title="Password"
          value={password}
          onChange={(e: any) => setPassword(e.target.value)}
          type="password"
        />
        <Button title="Login" handleClick={handleLogin} isLoading={isLoading} />
      </Box>
    </Container>
  );
}

export default SignIn;
