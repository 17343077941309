import Field from "../../components/Field";
import { useState, useEffect, useCallback, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./style.css";
import Button from "../../components/Button";
import Persons from "../../components/Persons";
import { GetCase, UpdateCaseReports, UpdateSpecimenToLims, UpdateOrder, UpdateSampleStatus } from "../../api/";
import { getUpdateCaseReportRequest, getCaseDataWithOnlyActiveSample, getLimsRequest, validateUserInput, checkIfLegalReportDataIsValid } from "./helpers";
import LimsDataModal from "../../components/LimsDataModal";
import { Received, SpecimenError } from "../../utils/constants";
import Checkbox from "../../components/CheckBox";

const SpecimenReception = () => {
    const caseFieldRef = useRef<HTMLElement>(null);
    const [id, setId] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingCaseUpdate, setIsLoadingCaseUpdate] = useState(false);
    const [isSendToLims, setIsSendToLims] = useState(false);
    const [specimenReceptionData, setSpecimenReceptionData] = useState<any>({});
    const [limsData, setLimsData] = useState({});
    const [isForLegalReport, setIsForLegalReport] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [message, setMessage] = useState("")
    const [disableFields, setDisableFields] = useState(false);

    const handleClose = () => setLimsData({});

    const GetPersons = () => {
        return specimenReceptionData.persons.map((p: any) => {
            return (
            <Persons 
                person={p} 
                reports={specimenReceptionData.reports} 
                key={p.personId} 
                updatePersonReportType={(personId: any, reportType: any) => updatePersonReportType(personId, reportType)}
                updatePersonComments={(personId: any, comments: any) => updatePersonComments(personId, comments)}
                updateCollectedDate={(personId: any, date: any) => updateCollectedDate(personId, date)}
                updateReceivedDate={(personId: any, date: any) => updateReceivedDate(personId, date)}
                isDisabled={(specimenReceptionData.status !== "Created" || disableFields)}
                updateIsConditionValid={(val : boolean, personId: string) => updateIsConditionValid(val, personId)}
                updateIsDocumentationValid={(val : boolean, personId: string) => updateIsDocumentationValid(val, personId)}
                isForLegalReport={isForLegalReport}
                updateCompletedBy= {(val : string, personId: string) => updateCompletedBy(val, personId)}
                updateCompletedAt= {(val : string, personId: string) => updateCompletedAt(val, personId)}
                updateChainOfCustody= {(val : boolean, personId: string) => updateChainOfCustody(val, personId)}
                updateRace = {(race: string, personId: string) => updatePersonRace(race, personId)}
            />)
        })
    }

    const updatePersonReportType = (personId: any, reportType: any) => {
        const updatedReportPersons = specimenReceptionData.reports.map((r: any) => {
            if (r.reportPersons.length > 0 && (typeof (r.reportPersons.find((rpe: any) => rpe.personId === personId)) !== "undefined")) {
                r.reportPersons.map((rp: any) => {
                    if (rp.personId === personId) {
                        rp.type = reportType
                    }
                    return rp
                })
            }
            else {
                var reportPerson = {
                    personId: personId,
                    type: reportType,
                }
                r.reportPersons.push(reportPerson);
            }
            return r
        })

        let specimenReceptionCopy = Object.assign({}, specimenReceptionData);
        specimenReceptionCopy.reports = updatedReportPersons;
        setSpecimenReceptionData(specimenReceptionCopy);
    }

    const updateCompletedBy = (val: string, personId: string) => {
        const updatedPersons = specimenReceptionData.persons.map((p: any) => {
            if (p.personId === personId) {
                p.samples.completedBy = val
            }
            return p;
        })
        let specimenReceptionCopy = Object.assign({}, specimenReceptionData);
        specimenReceptionCopy.persons = updatedPersons;
        setSpecimenReceptionData(specimenReceptionCopy);
    }

    const updateCompletedAt = (val: string, personId: string) => {
        const updatedPersons = specimenReceptionData.persons.map((p: any) => {
            if (p.personId === personId) {
                p.samples.completedAt = val
            }
            return p;
        })
        let specimenReceptionCopy = Object.assign({}, specimenReceptionData);
        specimenReceptionCopy.persons = updatedPersons;
        setSpecimenReceptionData(specimenReceptionCopy);
    }

    const updateChainOfCustody = (val: boolean, personId: string) => {
        const updatedPersons = specimenReceptionData.persons.map((p: any) => {
            if (p.personId === personId) {
                p.samples.isChainOfCustodyCompleted = val
            }
            return p;
        })
        let specimenReceptionCopy = Object.assign({}, specimenReceptionData);
        specimenReceptionCopy.persons = updatedPersons;
        setSpecimenReceptionData(specimenReceptionCopy);
    }

    const updateIsConditionValid = (val: boolean, personId: string) => {
        const updatedPersons = specimenReceptionData.persons.map((p: any) => {
            if (p.personId === personId) {
                p.samples.isConditionValid = val
            }
            return p;
        })
        let specimenReceptionCopy = Object.assign({}, specimenReceptionData);
        specimenReceptionCopy.persons = updatedPersons;
        setSpecimenReceptionData(specimenReceptionCopy);
    }

    const updateIsDocumentationValid = (val: boolean, personId: string) => {
        const updatedPersons = specimenReceptionData.persons.map((p: any) => {
            if (p.personId === personId) {
                p.samples.isDocumentationValid = val
            }
            return p;
        })
        let specimenReceptionCopy = Object.assign({}, specimenReceptionData);
        specimenReceptionCopy.persons = updatedPersons;
        setSpecimenReceptionData(specimenReceptionCopy);
    }


    const updatePersonComments = (personId: any, comments: any) => {
        const updatedPersons = specimenReceptionData.persons.map((p: any) => {
            if (p.personId === personId) {
                p.samples.comments = comments
            }
            return p;
        })
        let specimenReceptionCopy = Object.assign({}, specimenReceptionData);
        specimenReceptionCopy.persons = updatedPersons;
        setSpecimenReceptionData(specimenReceptionCopy);
    }

    const updateReceivedDate = (personId: any, date: any) => {
        const updatedPersons = specimenReceptionData.persons.map((p: any) => {
            if (p.personId === personId) {
                p.samples.receivedDate = date
            }
            return p;
        })
        let specimenReceptionCopy = Object.assign({}, specimenReceptionData);
        specimenReceptionCopy.persons = updatedPersons;
        setSpecimenReceptionData(specimenReceptionCopy);
    }

    const updateCollectedDate = (personId: any, date: any) => {
        const updatedPersons = specimenReceptionData.persons.map((p: any) => {
            if (p.personId === personId) {
                p.samples.collectedDate = date
            }
            return p;
        })
        let specimenReceptionCopy = Object.assign({}, specimenReceptionData);
        specimenReceptionCopy.persons = updatedPersons;
        setSpecimenReceptionData(specimenReceptionCopy);
    }

    const updatePersonRace = (race: string, personId: string) => {

        const updatedPersons = specimenReceptionData.persons.map((p: any) => {
            if (p.personId === personId) {
                p.race = race;
            }
            return p;
        })
        let specimenReceptionCopy = Object.assign({}, specimenReceptionData);
        specimenReceptionCopy.persons = updatedPersons;
        setSpecimenReceptionData(specimenReceptionCopy);
    }

    const updateCase = async (isContinue: boolean) => {
        try {
            validateUserInput(specimenReceptionData);
            if(isContinue) {
                setIsSendToLims(true)
            }
            else {
                setIsLoadingCaseUpdate(true);
            }
            var reportIds = specimenReceptionData.reports.map((r: any) => r.reportId);
            var request = getUpdateCaseReportRequest(reportIds, specimenReceptionData, isForLegalReport)
            var IsLegalReportAndValid = checkIfLegalReportDataIsValid(request.specimenReceptionData);
            if(!IsLegalReportAndValid){
                setErrorMessage("Please check the chain of custody is valid and other legal data is filled correctly")
                setIsLoadingCaseUpdate(false);
                setIsSendToLims(false)
                return;
            }
            UpdateCaseReports(request).then(async (res) => {
                if (res.data.updateCasePersonsByReportId.success && isContinue) {
                    var limsData = getLimsRequest(specimenReceptionData.persons, specimenReceptionData.externalCaseId, specimenReceptionData)
                    await handleGetCase(false);
                    setLimsData(limsData);
                }
                else if(!res.data.updateCasePersonsByReportId.success) {
                    setErrorMessage(res.data.updateCasePersonsByReportId.errorMessage ?? "Failed to update case")
                }
                else {
                    await handleGetCase(false);
                    successMessage("Saved!")
                }
            }).finally(() => {
                setIsLoadingCaseUpdate(false);
                setIsSendToLims(false)
            });
        }
        catch {
            setErrorMessage("Please fill the required data")
            setIsLoadingCaseUpdate(false);
            setIsSendToLims(false)
        }
    }

    const successMessage = (message : string) => {
        setShowSuccessMessage(true)
        setMessage(message)
        setTimeout(() => setShowSuccessMessage(false), 4000);
    }

    const handleGetCase = useCallback(async (showLoader: boolean) => {
        setDisableFields(false)
        console.log(`Get Case:${id} `)
        if(showLoader) {
            setIsLoading(true);
        }
        GetCase(id).then((caseData) => {
            var caseDataWithOnlyActiveSample = getCaseDataWithOnlyActiveSample(caseData.data.getCaseByCaseNumber);

            setSpecimenReceptionData(caseDataWithOnlyActiveSample);
            if (caseDataWithOnlyActiveSample.persons[0].samples.isLegalReportSample) {
                setIsForLegalReport(true)
            }
        }).catch((e) => {
            setSpecimenReceptionData(null);
            setErrorMessage("Unable to populate case information please contact IT")
        }).finally(() => {
            setIsLoading(false)
        })
    }, [id]);

    const handleSendToLims = async () => {
        var isCaseResolved = false;
        setIsSendToLims(true);
        var limsRequest = getLimsRequest(specimenReceptionData.persons, specimenReceptionData.externalCaseId, specimenReceptionData)
        UpdateSpecimenToLims(limsRequest).then(async (response) => {
            if(!response.data.upsertSpecimens.isSuccess) {
                throw new Error("Unable to send data to Lims please contact IT");
            }
            if(limsRequest.specimens.length === specimenReceptionData.persons.length) {
                isCaseResolved = true
            }
            for (const specimen of limsRequest.specimens) {
                var updateSampleRequest = {
                    specimenId: specimen.specimenId,
                    status: Received,
                }
                const contents = await UpdateSampleStatus(updateSampleRequest)
                if (!contents.data.updateSampleStatusInput.success) {
                    throw new Error('Unable to update sample')
                }
            }
            var request = {
                caseNumber: specimenReceptionData.caseNumber,
                caseExternalId: specimenReceptionData.externalCaseId,
                statusCode: isCaseResolved ? "" : SpecimenError
            }
            await UpdateOrder(request)

            setDisableFields(true)
            successMessage("Update successfully send to Lims")
            await handleGetCase(false);
        }).catch((e) => {
            setErrorMessage("Unable to send data to Lims please contact IT")
        }).finally(() => {
            setIsSendToLims(false)
            handleClose()
        });
    }

    useEffect(() => {
        const keyDownHandler = (event: any) => {
            const element = caseFieldRef.current;
            if (event.key === 'Enter' && element?.id === document.activeElement?.parentNode?.parentElement?.id ) {
                event.preventDefault();
                handleGetCase(true);
            }
        };
        document.addEventListener('keydown', keyDownHandler);
        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [handleGetCase]);


    useEffect(() => {
        setErrorMessage("");
    }, [specimenReceptionData])

    return (
        <Container style={{ marginTop: 50 }} fluid>
            <div className="case-search-header">Enter Case Number or External Case Number</div>
            <span ref={caseFieldRef} id="getCaseInputContainer"><Field fieldStyle={{float: 'left', width: 200, marginRight: 20}} type="text" title="" value={id} onChange={(e: any) => setId(e.target.value)} placeholder="Enter Case" labelStyle={{ fontSize: 16 }} >
            <Button style={{float: 'left'}} title="Get Case" handleClick={() => handleGetCase(true)} disabled={id ? false : true} isLoading={isLoading} />
            <Button variant="danger" title="Clear" handleClick={() => { setId(""); setSpecimenReceptionData({}) }} disabled={id ? false : true} style={{ marginLeft: 20 }} />
            </Field></span>
            <hr></hr>

            {specimenReceptionData?.externalCaseId && Object.keys(specimenReceptionData).length !== 0 && <>
                <Container fluid className="specimen-reception-header">
                    <Row>
                        <Col md={3} className="specimen-reception-header-label">Case Number: {specimenReceptionData.caseNumber}</Col>
                        <Col md={3} className="specimen-reception-header-label">ExternalCaseId: {specimenReceptionData.externalCaseId}</Col>
                        <Col md={3} className="specimen-reception-header-label">Product Code: {specimenReceptionData.externalProductCode} ({specimenReceptionData.personsInReport ?? 0})</Col>
                        <Col md={3} className="specimen-reception-header-label">Case Status: {specimenReceptionData.status}</Col>
                    </Row>
                    <Row>
                        <Col>
                            <Checkbox isDisabled={(specimenReceptionData.status !== "Created" || disableFields)} text="Legal Report" value={isForLegalReport} onChange={() => setIsForLegalReport(!isForLegalReport)}/>
                        </Col>
                    </Row>
                </Container>
                {((specimenReceptionData?.status !== "Created" && specimenReceptionData?.status !== "") || disableFields) && <div className="locked-case-message">Case cannot be updated as it has status "{specimenReceptionData?.status === "Created" ? "Specimen Received" : specimenReceptionData?.status}"</div>}
                {GetPersons()}
                
                <div className="specimen-reception-footer-elements"><Button disabled={disableFields || specimenReceptionData?.status !== "Created"} title="Save" handleClick={() => updateCase(false)} isLoading={isLoadingCaseUpdate} style={{ marginTop: 20, marginRight: 20 }} /> <Button variant="success" disabled={disableFields || specimenReceptionData?.status !== "Created"} title="Save and Continue" handleClick={() => updateCase(true)} isLoading={isSendToLims} style={{ marginTop: 20 }} />{errorMessage && Object.keys(limsData).length === 0 && <div className="specimen-reception-error">{errorMessage}</div>}
                    {showSuccessMessage && <div className="specimen-reception-success">{message}</div>}
                </div>
            </>}
            {specimenReceptionData == null && <div className="specimen-reception-no-case">No Case Found</div>
            }
            {Object.keys(limsData).length !== 0 && <LimsDataModal handleClose={handleClose} limsData={limsData} isShow={Object.keys(limsData).length !== 0} caseNumber={id} handleSendToLims={handleSendToLims} />}
        </Container>
    );
}

export default SpecimenReception;